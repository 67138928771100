import {pswpBlock} from './pswpBlock.js';
import {topMenuNav} from './topMenuNav.js';
import {languagePanel} from './languagePanel.js';
import {theme} from './theme';
import {sortTable} from './sortTable';
import {searchPanel} from './searchPanel';
import {upButton} from './upButton';
import {quicksearch} from "./quicksearch";

const device = require("current-device").default;
pswpBlock.init();
topMenuNav.init();
languagePanel.init();
theme.init();
sortTable.init();
searchPanel.init();
upButton.init();
quicksearch.init();
