export let sortTable = {
    init: function () {

        document.addEventListener('DOMContentLoaded', function() {
            const table = document.getElementById('sortable');
            if (!table) {
                return;
            }

            const headers = table.querySelectorAll('th');
            const tableBody = table.querySelector('tbody');
            const rows = tableBody.querySelectorAll('tr');

            // Направление сортировки
            const directions = Array.from(headers).map(function(header) {
                return '';
            });

            // Преобразовать содержимое данной ячейки в заданном столбце
            const transform = function(index, content) {
                // Получить тип данных столбца
                const type = headers[index].getAttribute('data-type');
                switch (type) {
                    case 'number':
                        return parseFloat(content);
                    case 'string':
                    default:
                        return content;
                }
            };

            const sortColumn = function(index) {
                // Получить текущее направление
                const direction = directions[index] || 'asc';

                // Фактор по направлению
                const multiplier = (direction === 'asc') ? 1 : -1;

                const newRows = Array.from(rows);

                newRows.sort(function(rowA, rowB) {
                    const cellA = rowA.querySelectorAll('td')[index].innerHTML;
                    const cellB = rowB.querySelectorAll('td')[index].innerHTML;

                    let a = transform(index, cellA);
                    let b = transform(index, cellB);

                    if (typeof a === 'string' && a.indexOf('<a') === 0) {
                        a = a.match(/<a[^\b>]+>(.+)[\<]\/a>/)[1];
                    }
                    if (typeof b === 'string' && b.indexOf('<a') !== -1) {
                        b = b.match(/<a[^\b>]+>(.+)[\<]\/a>/)[1];
                    }
                    switch (true) {
                        case a > b: return 1 * multiplier;
                        case a < b: return -1 * multiplier;
                        case a === b: return 0;
                    }
                });

                // Удалить старые строки
                [].forEach.call(rows, function(row) {
                    tableBody.removeChild(row);
                });

                // Поменять направление
                directions[index] = direction === 'asc' ? 'desc' : 'asc';

                // Добавить новую строку
                newRows.forEach(function(newRow) {
                    tableBody.appendChild(newRow);
                });
            };

            [].forEach.call(headers, function(header, index) {
                header.addEventListener('click', function() {
                    sortColumn(index);
                });
            });
        });
    }
}