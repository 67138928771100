export let theme = {
    button : null,

    init: function() {
        let that = this;
        that.button = document.querySelector(".topmenu .contrast-button");

        that.button.addEventListener('click', function(e) {
            e.preventDefault();
            let currentTheme = 'light';
            if (document.querySelector("body").classList.contains('dark')) {
                document.querySelector("body").classList.remove('dark');
                document.querySelector("body").classList.add('light');
            } else {
                document.querySelector("body").classList.remove('light');
                document.querySelector("body").classList.add('dark');
                currentTheme = 'dark';
            }
            that.sendThemeVar('/set-theme/', currentTheme);
        });
    },

    sendThemeVar: function(url, value)
    {
        const request = new XMLHttpRequest();
        const fullUrl = url + value;
        request.responseType =	"json";
        request.open("POST", fullUrl, true);
        request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        request.addEventListener("readystatechange", () => {
            //
            // if(request.readyState === 4 && request.status === 200) {
            //     console.log(request.responseText);
            // } else {
            //     console.log(request.responseText);
            // }
        });
        request.send();
    }

}
