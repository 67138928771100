export let pswpBlock = {

    init: function() {
        document.querySelectorAll("figure.image, .a-img-pswp").forEach(function(querySelector) {
            querySelector.addEventListener('click', function(e) {
                e.preventDefault();
                let slideNumber = 1;
                let pswpElement = document.querySelectorAll('.pswp')[0];

                let image = querySelector.querySelector('img');
                let items = Array();

                let img = new Image();
                img.onload = function() {

                    // Достаём заголовок из title или из figcaption (если оно есть)
                    let title = '';

                    if (image.closest('a') && image.closest('a').getAttribute('title')) {
                        title = image.closest('a').getAttribute('title');
                    }

                    if (image.closest('figure') && image.closest('figure').querySelector('figcaption')) {
                        title = image.closest('figure').querySelector('figcaption').innerHTML;
                    }

                    items.push({
                        src: image.getAttribute('src'),
                        w: this.width,
                        h: this.height,
                        title: title,
                    });
                    let gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, {index: slideNumber});
                    gallery.init();
                }
                img.src = image.getAttribute('src');
            });
        });
    },
}
