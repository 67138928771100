export let quicksearch = {
    control : null,
    panel : null,

    init: function() {
        this.control = document.querySelector("input#quicksearch");
        this.panel = document.querySelector(".glossary-list");

        if (this.control === null || this.panel === null) {
            return;
        }

        this.control.addEventListener('keyup', this.search);
        this.control.addEventListener('focusout', this.search);

        document.onkeydown = function(e){

            if(e.ctrlKey && e.code === 'KeyF'){
                e.preventDefault();
                document.querySelector("input#quicksearch").focus();
                return false;
            }
        }

    },

    search: function() {
        let i;
        let value = quicksearch.control.value.trim().toLowerCase();

        let items = document.querySelectorAll(".glossary-list a");
        for (i = 0; i < items.length; i++) {
            if(items[i].innerHTML.toLowerCase().includes(value)) {
                items[i].classList.remove('hide');
            } else {
                items[i].classList.add('hide');
            }
        }

        // Проверяем подзаголовки
        let subHeaders = document.querySelectorAll(".glossary-list > li");
        for (i = 0; i < subHeaders.length; i++) {
            let aVisible = subHeaders[i].querySelectorAll("a");
            let aHidden = subHeaders[i].querySelectorAll("a.hide");

            if (aVisible.length === aHidden.length) {
                subHeaders[i].classList.add('hide');
            } else {
                subHeaders[i].classList.remove('hide');
            }
        }

    }

}
