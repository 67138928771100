export let searchPanel = {

    ajaxHandler: null,
    field: null,
    preloader: null,
    overlay: null,
    resultsPanel: null,
    locale: 'ru',
    lastSearchUrl: null,

    init: function () {
        document.addEventListener('DOMContentLoaded', function() {

            searchPanel.field = document.querySelector('.search-panel input');
            searchPanel.preloader = document.querySelector('.search-panel .preloader');
            searchPanel.resultsPanel = document.querySelector('.search-panel .search-results');
            searchPanel.overlay = document.querySelector('.overlay');
            searchPanel.locale = document.querySelector('html').attributes['lang'].value;

            searchPanel.ajaxHandler = new XMLHttpRequest();

            searchPanel.ajaxHandler.onreadystatechange = function() {

                searchPanel.hidePreloader();

                if (searchPanel.ajaxHandler.readyState === XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
                    if (searchPanel.ajaxHandler.status === 200) {


                        let results = JSON.parse(searchPanel.ajaxHandler.responseText);
                        let listHtml = '';

                        for(let i = 0; i < results.items.length; i++) {
                            listHtml += '<a href="'+results.items[i].link+'">'+results.items[i].name + '<i>' + results.items[i].typeName + '</i></a>';
                        }
                        searchPanel.resultsPanel.innerHTML = listHtml;
                        searchPanel.showResults();

                    }
                    else if (searchPanel.ajaxHandler.status === 400) {
                        // console.log('There was an error 400');
                    }
                    else {
                        // console.log('Something else other than 200 was returned: ' + searchPanel.ajaxHandler.status);
                    }
                }
            };

            searchPanel.field.addEventListener('keyup', searchPanel.fieldAction);
            searchPanel.field.addEventListener('focusout', searchPanel.fieldAction);
            searchPanel.overlay.addEventListener('click', searchPanel.hideResults);

        });
    },

    fieldAction: function() {
        const value = searchPanel.field.value;
        if (value.length < 2) return;

        if (searchPanel.ajaxHandler.readyState !== XMLHttpRequest.DONE && searchPanel.ajaxHandler.readyState !== XMLHttpRequest.UNSENT) {
            searchPanel.ajaxHandler.abort();
        }

        let searchUrl = "/"+searchPanel.locale+"/quicksearch?text=" + value;
        if (searchPanel.lastSearchUrl !== searchUrl) {
            searchPanel.ajaxHandler.open("GET", searchUrl, true);
            searchPanel.showPreloader();
            searchPanel.hideResults();
            searchPanel.ajaxHandler.send();
            searchPanel.lastSearchUrl = searchUrl;
        }
    },

    showPreloader: function() {
        searchPanel.preloader.classList.add('visible');
    },
    hidePreloader: function() {
        searchPanel.preloader.classList.remove('visible');
    },
    showResults: function() {
        searchPanel.resultsPanel.classList.add('visible');
        searchPanel.overlay.classList.add('visible');
    },
    hideResults: function() {
        searchPanel.resultsPanel.classList.remove('visible');
        searchPanel.overlay.classList.remove('visible');
    }
}