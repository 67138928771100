export let upButton = {
    init: function() {
        this.checkButton();
        document.querySelector('.up-button').addEventListener('click', function(e) {
            e.preventDefault();
            window.scrollBy({
                top: -window.scrollY,
                behavior: 'smooth'
            });
        }, true);
        window.addEventListener('scroll', this.checkButton, true);
    },

    checkButton: function() {
        let button = document.querySelector('.up-button');
        if (window.scrollY > 100) {
            button.classList.remove('hidden');
        } else {
            button.classList.add('hidden');
        }
    }
}