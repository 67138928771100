export let languagePanel = {
    button : null,
    panel : null,

    init: function() {
        let that = this;
        that.button = document.querySelector(".topmenu .language-button");
        that.panel = document.querySelector(".topmenu .language-panel-popup");


        that.button.addEventListener('click', function(e) {
            e.preventDefault();
            that.panel.classList.toggle('visible');
        });
    }
}
