import {theme} from "./theme";

export let topMenuNav = {
    menu: null,
    button: null,
    buttonPanel: null,
    overlay: null,

    init: function() {
        let that = this;


        that.button = document.querySelector(".topmenu .bars");
        that.buttonPanel = document.querySelector(".topmenu .button-panel");
        that.menu = document.querySelector(".left-menu");
        that.overlay = document.querySelector('.overlay');

        that.overlay.addEventListener('click', topMenuNav.hide);

        if (window.innerWidth > 800) {
            // that.show();
        }


        if (!that.button.classList.contains('right') && !that.button.classList.contains('left')) {
            let windowWidth = window.innerWidth;

            if (windowWidth > 800) {
                that.button.classList.remove('right');
                that.button.classList.add('left');
                that.buttonPanel.classList.add('open');
                that.show();
            } else {
                that.button.classList.remove('left');
                that.button.classList.add('right');
                that.buttonPanel.classList.remove('open');
                that.hide();
            }
        }

        that.button.addEventListener('click', function(e) {

            e.preventDefault();

            if (that.button.classList.contains('left')) {
                // Закрываем меню
                that.button.classList.remove('left');
                that.button.classList.add('right');
                that.buttonPanel.classList.remove('open');
                that.hide();

            } else if (that.button.classList.contains('right')) {

                // Открываем меню
                that.button.classList.remove('right');
                that.button.classList.add('left');
                that.buttonPanel.classList.add('open');

                that.show();
            } else {
                // Состояние не определено, находим исходя из размера и типа экрана
            }
        });
    },

    show: function()
    {
        topMenuNav.menu.classList.remove('hidden');
        topMenuNav.updateServerMenuState();

        if (window.innerWidth < 800) {
            topMenuNav.overlay.classList.add('visible');
        }

        setTimeout(function() {
            topMenuNav.menu.classList.remove('animated-submenu');
        }, 300);
    },

    hide: function()
    {
        topMenuNav.menu.classList.add('hidden');
        topMenuNav.updateServerMenuState();
        topMenuNav.overlay.classList.remove('visible');

        topMenuNav.button.classList.remove('left');
        topMenuNav.button.classList.add('right');

        setTimeout(function() {
            topMenuNav.menu.classList.add('animated-submenu');
        }, 300);
    },

    toggle: function()
    {
        if (this.menu.classList.contains('hidden')) {
            this.show();
        } else {
            this.hide();
        }
        this.updateServerMenuState();
    },

    updateServerMenuState: function() {
        let state = 'open';
        if (window.innerWidth < 800 || this.menu.classList.contains('hidden')) {
            state =  'closed';
        }


        theme.sendThemeVar('/set-menu-state/', state);
    }

}