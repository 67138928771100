/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './photoswipe/photoswipe.css';
import './photoswipe/default-skin/default-skin.css';
import '../node_modules/flag-icons/css/flag-icons.css';
import './styles/app.scss';
import "current-device";
import './js/app.js';

// start the Stimulus application
import './bootstrap';
